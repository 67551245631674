<template>
  <div class="home-page">
    <div class="home-page__intro">
      <img v-lazy-load 
        data-src="@/public/img/intro.webp"
        alt="intro"
        class="home-page__intro-img"
      />
      <div class="container">
        <div
          class="home-page__intro-wrapper"
          :class="{ 'home-page__intro-wrapper--auth': userStore.isAuth }"
        >
          <div v-if="userStore.isAuth" class="home-page__actions">
            <nuxt-link
              v-for="item in userModeStore.getHomeActionsLink"
              :key="item.id"
              class="home-page__actions-item"
              :to="item.link"
            >
              <v-icon class="home-page__actions-icon">{{ item.icon }}</v-icon>
              <span class="home-page__actions-title">{{ item.name }}</span>
            </nuxt-link>
          </div>
          <div v-else class="home-page__intro-request">
            <span class="home-page__intro-request-title"
              >Почніть з вибору автомобіля</span
            >
            <RequestForm v-model="currentCar" :fullForm="false" />
            <v-btn
              class="mt-2"
              size="large"
              color="black"
              block
              @click="confirmForm"
              variant="flat"
              :disabled="currentCar.car_generation_id === null"
            >
              Продовжити
            </v-btn>
          </div>
          <div class="home-page__intro-description">
            <h1 class="home-page__intro-title">
              Залиште заявку на купівлю автозапчастин вже зараз!
            </h1>
            <p class="home-page__intro-text">
              Наш сервіс дозволяє вам швидко і зручно знайти необхідні
              автозапчастини. Просто заповніть форму, і продавці з усієї України
              надішлють вам свої пропозиції.
            </p>
            <h2>Підберемо для Вас запчастину</h2>
          </div>
        </div>
      </div>
    </div>

    <div
      class="home-page__services"
      :class="{ 'home-page__services--auth': userStore.isAuth }"
    >
      <div class="container">
        <div class="home-page__services-wrapper">
          <div
            v-for="item in serviceList"
            :key="item.title"
            class="home-page__service-item"
          >
            <div class="home-page__service-item-title">
              <component :is="item.icon" />
              <h3>{{ item.title }}</h3>
            </div>
            <div class="home-page__service-item-description">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-page__contact">
      <div class="container">
        <div class="home-page__contact-wrapper">
          <h2 class="home-page__title home-page__title--white mb-3">
            Зв'яжіться з нами
          </h2>
          <p class="home-page__contact-description">
            Маєте питання або потрібна допомога? Натисніть кнопку "Зв'яжіться з
            нами", щоб отримати оперативну підтримку від нашої команди. Ми
            завжди раді допомогти вам з будь-якими запитами щодо пошуку та
            замовлення автозапчастин. Ваші питання та зауваження важливі для
            нас, і ми зробимо все можливе, щоб забезпечити вам найкращий сервіс.
          </p>

          <div class="home-page__contact-button">
            <v-btn size="large" color="white" block variant="outlined">
              Зв'яжіться з нами
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="home-page__work">
      <div class="container">
        <div class="home-page__work-wrapper">
          <h2 class="home-page__title">Як це працює</h2>
          <div class="home-page__work-list">
            <div
              v-for="(item, index) in howItWorksList"
              :key="index"
              class="home-page__work-item"
            >
              <span class="home-page__work-item-icon"> {{ index + 1 }}</span>
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-page__questions">
      <div class="container">
        <div class="home-page__questions-wrapper">
          <h2 class="home-page__title mb-8">Поширені запитання</h2>
          <SupportAccordions />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/useUserStore";
import SupportAccordions from "@/components/SupportAccordions.vue";
import IconSpeed from "@/components/Icons/IconSpeed.vue";
import IconWrench from "@/components/Icons/IconWrench.vue";
import IconSecurity from "@/components/Icons/IconSecurity.vue";
import { useUserModeStore } from "@/stores/useUserModeStore";

import RequestForm from "@/components/RequestForm.vue";

const userStore = useUserStore();
const userModeStore = useUserModeStore();

const serviceList = ref([
  {
    title: "Швидкість",
    description:
      "Отримуйте пропозиції від продавців моментально, економлячи час на пошук.",
    icon: markRaw(IconSpeed),
  },
  {
    title: "Зручність",
    description:
      "Інтуїтивно зрозумілий інтерфейс дозволяє легко знайти необхідні запчастини.",
    icon: markRaw(IconWrench),
  },
  {
    title: "Надійність",
    description:
      "Співпрацюємо тільки з перевіреними постачальниками для вашої впевненості.",
    icon: markRaw(IconSecurity),
  },
]);

const howItWorksList = [
  "Як це працює",
  "Отримайте пропозиції",
  "Обирайте найкраще",
  "Отримайте запчастину",
];

const currentCar = ref({
  car_brand_id: null,
  car_model_id: null,
  car_generation_id: null,
  car_engine_type_id: null,
  car_engine_capacity_id: null,
  car_transmission_id: null,
});

const confirmForm = () => {
  localStorage.setItem("currentCar", JSON.stringify(currentCar.value));
  navigateTo("/login");
};
</script>

<style lang="scss" scoped>
.home-page {
  padding-bottom: 100px;

  @media (max-width: 960px) {
    padding-bottom: 80px;
  }

  @media (max-width: 600px) {
    padding-bottom: 40px;
  }

  &__intro {
    position: relative;
    padding: 80px 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;

      @media (max-width: 600px) {
        display: none;
      }
    }

    @media (max-width: 600px) {
      padding: 24px 0 100px;
      background: var(--color-main-dark);
    }
  }

  &__intro-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    z-index: 1;
    object-fit: cover;

    &.isLoaded {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 600px) {
      display: none !important;
    }
  }

  &__intro-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    position: relative;
    z-index: 3;

    @media (max-width: 960px) {
      flex-direction: column-reverse;
      position: relative;
      padding-bottom: 136px;
    }

    @media (max-width: 960px) {
      padding-bottom: 0;
    }
  }

  &__intro-wrapper--auth {
    flex-direction: column-reverse;
    gap: 90px;
    padding: 45px 0;

    .home-page__intro-description {
      text-align: center;

      @media (max-width: 960px) {
        text-align: left;
      }
    }

    @media (max-width: 600px) {
      padding: 0;
      background: var(--color-main-dark);
    }
  }

  &__actions {
    display: flex;
    padding: 18px;
    gap: 12px;
    background: var(--color-main-white);
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    @media (max-width: 960px) {
      gap: 10px;
      padding: 16px;
    }

    @media (max-width: 600px) {
      position: absolute;
      top: 60px;
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;
      gap: 0;
      width: 327px;
      height: 221px;
      padding: 0;
    }
  }

  &__actions-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 132px;
    height: 86px;
    color: var(--color-main-dark);

    @media (max-width: 600px) {
      width: 50%;
      height: 50%;

      &:nth-child(1) {
        border-right: 1px solid var(--color-border-default);
        border-bottom: 1px solid var(--color-border-default);
      }

      &:nth-child(2) {
        border-left: 1px solid var(--color-border-default);
        border-bottom: 1px solid var(--color-border-default);
      }

      &:nth-child(3) {
        border-right: 1px solid var(--color-border-default);
        border-top: 1px solid var(--color-border-default);
      }

      &:nth-child(4) {
        border-left: 1px solid var(--color-border-default);
        border-top: 1px solid var(--color-border-default);
      }
    }
  }

  &__actions-icon {
    font-size: 32px;
  }

  &__actions-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;
  }

  &__intro-request {
    width: 480px;
    background-color: #f5f5f5;
    flex-shrink: 0;

    @media (max-width: 960px) {
      position: absolute;
      top: 200px;
    }

    @media (max-width: 600px) {
      position: absolute;
      top: 50px;
    }
  }

  &__intro-title {
    color: var(--color-main-white);
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.085px;
    margin-bottom: 40px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__intro-text {
    color: var(--color-main-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__intro-request {
    padding: 40px;
    border-radius: 8px;
    background: var(--color-main-white);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    @media (max-width: 600px) {
      padding: 24px 12px;
      max-width: 100%;
    }
  }

  &__intro-description {
    h2 {
      display: none;
      color: var(--color-main-white);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.05px;
      @media (max-width: 600px) {
        display: block;
      }
    }
  }

  &__intro-request-title {
    display: block;
    text-align: center;
    margin-bottom: 32px;
    color: var(--color-primary-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__services--auth {
    @media (max-width: 600px) {
      margin-top: 140px;
    }
  }

  &__services {
    padding: 60px 0;

    @media (max-width: 960px) {
      padding: 32px 0;
      margin-top: 195px;
    }

    @media (max-width: 600px) {
      margin-top: 195px;
    }
  }

  &__services-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 960px) {
      flex-direction: column;
    }
  }

  &__service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  &__service-item-title {
    display: flex;
    align-items: center;
    gap: 12px;

    h3 {
      color: var(--color-primary-black);
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;

      @media (max-width: 600px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.024px;
      }
    }
  }

  &__service-item-description {
    color: var(--color-secondary-black);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;

    @media (max-width: 600px) {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.048px;
    }
  }

  &__contact {
    background: var(--color-main-dark);
    color: var(--color-main-white);
    padding: 32px 0;

    @media (max-width: 960px) {
      padding: 24px 0;
    }
  }

  &__title {
    color: var(--color-primary-black);
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 117.647% */
    letter-spacing: 0.085px;

    &--white {
      color: var(--color-main-white);
    }

    @media (max-width: 960px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.05px;
    }
  }

  &__contact-description {
    color: #ccc;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.048px;
    }
  }

  &__contact-button {
    max-width: 200px;
    margin: 32px auto 0;

    @media (max-width: 600px) {
      margin: 24px auto 0;
    }
  }

  &__work {
    padding: 60px 0;

    @media (max-width: 960px) {
      padding: 32px 0 50px;
    }
  }

  &__work-list {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    margin-top: 40px;

    @media (max-width: 960px) {
      width: max-content;
      flex-direction: column;
      margin: 24px auto 0;
    }
  }

  &__work-item-icon {
    border-radius: 20px;
    background: var(--color-main-success);
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-main-white);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__work-item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--color-main-dark);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.024px;
  }

  &__questions-wrapper {
    @media (max-width: 960px) {
      max-width: 600px;
      margin: 0 auto;
    }
  }
}
</style>
